import React from 'react';
import Layout from '../components/Layout';

const IndexPage = () => {

  return (
    <Layout fullMenu>
      <section id="banner">
        <div className="inner">
          <div className="logo">
            <span className="icon fa-diamond"></span>
          </div>
          <h2>BWC Admin</h2>
          <p>Broken Window Creations Administration Page</p>
        </div>
      </section>
    </Layout>
  )
};

export default IndexPage;
